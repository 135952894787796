const defaultOrder = ["ss", "sskyp", "vermeil", "ky", "kw", "kr"]

export default (looks) => {
  const order = [...defaultOrder].reverse()

  let newLooks = [...looks]
  newLooks.sort((a, b) => -order.indexOf(a) - -order.indexOf(b))

  return newLooks
}
