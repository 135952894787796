import { push, replace } from "connected-react-router"
import queryString from "query-string"
import React from "react"
import { connect } from "react-redux"
import scroll from "scroll"
import scrollDoc from "scroll-doc"

import { history } from "app-store-v2/index"
import {
  addToWishlist,
  confirmAddToWishlist,
  setGridView,
  unselectFromWishlist,
  updateCurrentPage,
  updatePriceRange,
} from "areas/collection/store/actions"

export default function withCollection(WrappedComponent) {
  const InternalCollection = (props) => {
    return <WrappedComponent {...props} />
  }

  const mapStateToProps = (state, ownProps) => {
    const wishlistAdded = (state.wishlist.line_items || []).some(
      (line_item) =>
        line_item.style_code === ownProps.product?.style_code ||
        line_item.style_code === ownProps.product?.rm_style_code
    )
    return {
      banner: state.collection.banner,
      choices: state.choices,
      currentPage: state.collection.pagination.current_page_number,
      filteredPriceRange: state.collection.filtered_price_range,
      gridView: state.collection.gridView,
      loadingMoreProducts: state.collectionUI.loadingMoreProducts,
      newPageStartIndex: state.collectionUI.newPageStartIndex,
      no_result_message: state.collectionUI.no_result_message,
      pagination: state.collection.pagination,
      placeholders: state.collection.placeholders,
      priceRange: state.collection.filter_menu.default_price_range,
      products: state.collection.product_result,
      productsLoaded:
        state.collection.product_result.length > 0 &&
        !state.collectionUI.loading,
      resultsPerPage: state.collection.pagination.results_per_page,
      showLoading: state.collectionUI.loading,
      subtitle: state.collection.subtitle,
      tempHardcodedFilters: [
        { link: "/engagement", name: "Engagement" },
        { link: "/bridal", name: "Bridal" },
        { link: "/for-her", name: "For Her" },
        { link: "/for-him", name: "For Him" },
      ],
      title: state.collection.title,
      totalPage: state.collection.pagination.total_page_count,
      totalProducts: state.collection.pagination.total_products_count,
      wishlistAdded,
      wishlistCount: state.wishlist.count,
      wishlistLoading: state.wishlist.loading,
    }
  }

  const mapDispatchToProps = (dispatch) => {
    return {
      detectScrollPageProduct: (product, ref) => {
        let init = true
        let triggeredBottom = false
        let triggeredTop = false
        return () => {
          if (ref && ref.current) {
            const elem = ref.current
            const rect = elem.getBoundingClientRect()
            if (!rect) {
              return
            }
            const fromBottom = rect.top - window.innerHeight
            const fromTop = rect.top

            if (init) {
              triggeredBottom = fromBottom < 0
              triggeredTop = fromTop > 0
              // If the element is in view and not the first page
              if (product.pageNum > 1 && triggeredBottom && triggeredTop) {
                dispatch(replace(`/${product.pageHref}`))
              }
              init = false
              return
            }
            // Element has appeared from the bottom
            if (fromBottom < 0 && !triggeredBottom) {
              dispatch(replace(`/${product.pageHref}`))
              triggeredBottom = true
            } else if (fromTop > 0 && !triggeredTop) {
              // Element has appeared from the top
              dispatch(replace(`/${product.pageHref}`))
              triggeredTop = true
            } else if (fromTop < 0 && fromBottom < 0 && triggeredTop) {
              // Element has been scrolled past the top, resetting the flag
              triggeredTop = false
            } else if (fromTop > 0 && fromBottom > 0 && triggeredBottom) {
              // Element has been scrolled past the bottom, resetting the flag
              triggeredBottom = false
            }
          }
        }
      },
      dispatch,
      nextPage: (page) => {
        let parsed = queryString.parse(history.location.search)
        parsed.page = page
        dispatch(
          push(`${history.location.pathname}?${queryString.stringify(parsed)}`)
        )
      },
      scrollTop: () => {
        if (typeof document === "undefined") {
          return
        }
        const element = document.getElementById("collection-scroll-back-point")
        if (element) {
          let yOffset = element.offsetTop
          scroll.top(scrollDoc(), yOffset)
        }
      },
      setGridView: (view) => {
        dispatch(setGridView(view))
      },
      updatePage: (page) => {
        dispatch(updateCurrentPage(page))
      },
      updatePriceRange: (values) => {
        dispatch(updatePriceRange(values[0], values[1]))
      },
    }
  }

  const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { wishlistAdded, wishlistCount, wishlistLoading } = stateProps
    const { product, selectedProductLook } = ownProps
    const { dispatch } = dispatchProps

    return {
      ...ownProps,
      ...stateProps,
      ...dispatchProps,
      addToWishlist: (productParams) => {
        dispatch(
          addToWishlist(
            productParams.rm_style_code || productParams.style_code,
            productParams.selected_look,
            productParams.source
          )
        )
      },
      confirmAddToWishlist: (toggle) => {
        dispatch(confirmAddToWishlist(toggle))
      },
      toggleAddToWishlist: (
        confirmWishlistDialog,
        setProductParams,
        e,
        setLoading
      ) => {
        if (wishlistLoading) return null
        e.preventDefault()
        e.stopPropagation()
        const styleCode = product.rm_style_code || product.style_code
        if (wishlistAdded) {
          dispatch(unselectFromWishlist(styleCode, "Collection"))
        } else if (wishlistCount >= sl.config.max_wishlist_items) {
          setLoading(false)
          dispatch(confirmAddToWishlist(false))
          if (setProductParams) {
            setProductParams({
              selected_look: selectedProductLook,
              source: "Collection",
              style_code: styleCode,
            })
          }
          confirmWishlistDialog(true)
        } else {
          dispatch(addToWishlist(styleCode, selectedProductLook, "Collection"))
        }
      },
    }
  }

  const Collection = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )(InternalCollection)

  return Collection
}
